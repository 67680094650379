import React from 'react'

import Layout from '../components/layout/layout'
import SEO from '../components/seo/seo'
import video from '../videos/newsletter-thank-you-all-rounder.mp4'

const ThankYouAllRounder = () => (
  <Layout>
    <SEO title="Thank You All-rounder" />
    <article>
      <div className="c-cta-content">
        <div className="container">
          <div className="row">
            <div className="c-cta-content__title-container">
              <h2 className="c-cta-content__title">Thanks for joining the team, you're fielding.</h2>
            </div>
            <div className="c-cta-content__content-container">
              <div className="cms-content">
                <p>
                  You will receive regular updates and information on everything The Edge, including advance notice on events, bonus content, and early access to exclusive videos sent to your email.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="c-video">
        <div className="container">
          <div className="row">
            <div className="c-video__container">
              <video
                src={video}
                className="c-video__media"
                autoPlay playsInline controls>
              </video>
            </div>
          </div>
        </div>
      </div>
    </article>
  </Layout>
)

export default ThankYouAllRounder
